<template>
  <div class="agent-card">
    <div class="agent-withdraw-record">
      <div class="withdraw-record-item" v-for="(item, index) in recordList" :key="index">
        <div>
          <p class="field">
            <strong class="record-item-amount">{{item.currency}}{{item.money}}</strong>
          </p>
          <p>{{item.createTime}}</p>
        </div>
        <div class="text-right">
          <p :class="[item.textClass]">{{item.statusTip}}</p>
          <p>{{item.account}}</p>
        </div>
      </div>
      <div class="text-center mt-1">
        <span class="text-small" v-if="page > total">沒有更多了</span>
        <div v-else>
          <div class="text-center" v-if="fetching">
            <div class="loading-icon"></div>
          </div>
          <button class="btn default btn-sm" @click="fetchAgentRecord" v-else>加载更多</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'src/api'
import moment from 'moment'
export default {
  name: 'withdraw-record',
  data: () => ({
    dataLoaded: false,
    recordList: [],
    page: 0,
    total: 1,
    fetching: false
  }),
  computed: {},
  mounted () {
    this.fetchAgentRecord()
  },
  methods: {
    fetchAgentRecord () {
      if (this.fetching) return
      this.fetching = true
      this.page++
      if (this.page > this.total) return
      api.fetchWithdrawRecord(this.page).then(data => {
        const { pages } = data
        if (!this.dataLoaded) {
          this.dataLoaded = true
          this.total = pages
        }
        data.data = data.data.map(item => {
          item.createTime = moment(item.updatedAt).format('YYYY-MM-DD HH:mm')
          item.textClass = item.status === 2 ? 'text-success' : (item.status === 3 ? 'text-danger' : '')
          let tip = '返現中'
          switch (item.status) {
            case 2:
              tip = '已完成'
              break
            case 3:
              tip = '返現失敗'
              break
            case 1:
            default:
              tip = '返現中'
              break
          }
          item.statusTip = tip
          return item
        })
        this.recordList = this.recordList.concat(data.data)
        this.fetching = false
      })
    }
  }
}
</script>

<style type="text/scss" lang="scss">
  .agent-withdraw-record {
    text-align: left;
    .withdraw-record-item {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid #eee;
    }
    .record-item-amount {
      font-weight: 600;
      font-size: 14px;
    }
  }
</style>
